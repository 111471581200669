@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    li {
        @apply p-4
    }

    button {
        @apply text-white border bg-indigo-600 border-indigo-600
        hover:bg-white hover:text-indigo-600 rounded-md
    }
}